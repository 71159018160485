<template>
  <div>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <Dialog
        :dialog="dialog"
        :title="'Neue Hochzeit anlegen'"
        :values="weddingCreateFields"
        :successButton="'Erstellen'"
        :icon="'mdi-ring'"
        :headLines="[]"
        :cut="3"
        @hideDialog="hideDialog"
        @emitNewData="createWedding"
        @createEntry="startCreateCustomer($event)"
        @dataChanged="valueChanged"
    ></Dialog>
    <CreateCustomer
        :dialog="dialogCustomer"
        @hideDialog="hideDialogCustomer"
        @createCustomer="customerCreated"
    >
    </CreateCustomer>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const Dialog = () => import("@/components/dialog/FormDialogNew");
const CreateCustomer = () => import("@/components/dialog/createDialogs/CreateCustomer");

export default {
  name: "CreateWedding",
  components: {
    Dialog,
    Message,
    CreateCustomer
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('settings', {
      weddingStatus: 'weddingStatusForSelection',
      myProducts: 'myProductsForSelection'
    }),
    ...mapGetters('customer', {
      customers: 'customersForSelection',
      createdCustomerId: 'customerCreatedId'
    }),
  },
  mounted() {
    this.$store.dispatch('settings/getStatusForSelection', {
      uid: this.user.id,
    }).then(()=>{
      this.$store.dispatch('customer/getCustomersForSelection', {
        uid: this.user.id
      }).then(()=>{
        this.$store.dispatch('settings/getMyProductsForSelection', {
          uid: this.user.id
        }).then(()=>{
          this.weddingCreateFields = this.getWeddingCreateFields()
        }).catch((err)=>{
          this.message = error(err)
        })
      }).catch((err)=>{
        this.message = error(err)
      })
    }).catch((err)=>{
      this.message = error(err)
    })
  },
  data() {
    return {
      createFields: [],
      dialogCustomer: false,
      message: msgObj(),
      weddingCreateFields:  this.getWeddingCreateFields()
    }
  },
  props: {
    dialog: Boolean,
    customerSelection: String,
    openAfterCreate: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  methods: {
    hideDialog() {
      this.$emit('hideDialog')
    },
    hideDialogCustomer() {
      this.dialogCustomer = false
    },
    startCreateCustomer() {
      this.dialogCustomer = true
    },
    valueChanged(payload){
      if(payload.title === 'Typ der Planung'){
        let price = this.getPriceForProduct(payload.value)
        console.log(price)
        for( let createField of this.weddingCreateFields)
        {
          if(createField.title === 'Preis in €')
          {
            console.log(createField)
            createField.value = price
          }
        }
      }
    },
    getPriceForProduct(type){
      for(let product of this.myProducts){
        if(product.value === type){
          return product.price
        }
      }
    },
    customerCreated() {
      this.$store.dispatch('customer/getCustomersForSelection', {
        uid: this.user.id
      }).then(() => {
        this.weddingCreateFields = [
          {
            title: 'Kunde*',
            value: this.createdCustomerId,
            id: 0,
            items: this.customers,
            type: 'autoComplete',
            icon: 'mdi-account-plus',
            entity: 'customer'
          },
          {
            title: 'Status',
            value: null,
            id: 1,
            items: this.weddingStatus,
            type: 'autoComplete',
          },
          {
            title: 'Hochzeitsdatum',
            value: null,
            id: 2,
            type: 'date'
          },
          {
            title: 'Typ der Planung',
            value: null,
            id: 3,
            type: 'autoComplete',
            items: this.myProducts
          },
        ]
      })
    },
    createWedding(newValues) {
      let newWedding = this.getWeddingFromInput(newValues)
      this.$store.dispatch('wedding/createWedding', {
        newWedding,
        uid: this.user.id,
        openAfterCreate: this.openAfterCreate
      }).then(() => {
            this.hideDialog()
            this.message = success('Hochzeit wurde erfolgreich angelegt.')
          }
      ).catch((err) => {
        this.message = error(err)
      })
    },
    getWeddingCreateFields() {
      return [
        {
          title: 'Kunde*',
          value: this.customerSelection,
          id: 0,
          items: this.customers,
          type: 'autoComplete',
          icon: 'mdi-account-plus',
          entity: 'customer'
        },
        {
          title: 'Status',
          value: null,
          id: 1,
          items: this.weddingStatus,
          type: 'autoComplete',

        },
        {
          title: 'Hochzeitsdatum',
          value: null,
          id: 2,
          type: 'date'
        },
        {
          title: 'Typ der Planung',
          value: null,
          id: 3,
          type: 'autoComplete',
          items: this.myProducts
        },

      ]
    },
    getWeddingFromInput(newValues) {
      let newWedding = {
        customer: null,
        wedDate: null,
        customerId: '',
        status: '',
        price: 0,
        type: '',
      }
      for (let value of newValues) {
        switch (value.title) {
          case 'Kunde*':
            newWedding.customerId = value.value
            break;
          case 'Status':
            newWedding.status = value.value
            break;
          case 'Typ der Planung':
            newWedding.type = value.value
            break;
          case 'Preis in €':
            newWedding.price = value.value
            break;
          case 'Hochzeitsdatum':
            if (value.value) {
              let date = new Date(value.value)
              newWedding.wedDate = date.getTime()
            } else {
              newWedding.wedDate = null
            }
            break;
        }
      }
      return newWedding
    },

  },
}
</script>
